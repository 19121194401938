import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {CircularProgress} from "@mui/material";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <CircularProgress />;
    }

    return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
